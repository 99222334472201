<template>
  <div class="home">
    <div class="left">
      <div class="item">
        <device-count :deviceNumberStatisticResVo="deviceNumberStatisticResVo" />
      </div>
      <div class="item">
        <device-top10 :chartData="deviceRegionStatisticResVoList" />
      </div>
    </div>

    <div class="middle">
      <div class="item">
        <USAMap :chartData="deviceProvinceStatisticResVoList" v-if="$i18n.locale == 'en'" />
        <world-map :chartData="countryStatisticResVos" v-else />
      </div>
      <div class="item deviceAlert">
        <device-alert :chartData="deviceWarningStatisticResVoList" />
      </div>
    </div>

    <div class="right">
      <div class="item">
        <device-app :chartData="deviceAppStatisticResVoList" />
      </div>

      <div class="item">
        <version-count :chartData="VersionCountList" />
      </div>
    </div>
  </div>
</template>

<script>
import DeviceApp from './components/DeviceApp'
import DeviceCount from './components/DeviceCount'
import DeviceTop10 from './components/DeviceTop10'
import DeviceAlert from './components/DeviceAlert'
import VersionCount from './components/VersionCount'
// import ChinaMap from './components/ChinaMap'
import WorldMap from './components/WorldMap'
import USAMap from './components/USAMap'

import watchParamsGetData from '@/mixins/watchParamsGetData'
export default {
  mixins: [watchParamsGetData],
  data() {
    return {
      deviceNumberStatisticResVo: {
        increaseWeek: 0,
        increaseMonth: 0,
        increaseTotal: 0,
        offlineNumber: 1,
        onlineNumber: 0,
        comparedLastWeek: 0,
        comparedLastMonth: 0
      },
      deviceAppStatisticResVoList: [],
      deviceWarningStatisticResVoList: [],
      deviceRegionStatisticResVoList: [],
      deviceProvinceStatisticResVoList: [],
      countryStatisticResVos: [],
      VersionCountList: []
    }
  },
  components: {
    DeviceApp,
    DeviceCount,
    DeviceTop10,
    DeviceAlert,
    VersionCount,
    // ChinaMap,
    WorldMap,
    USAMap
    // DeviceType,
    // DeviceGroup,
    // DeviceArea,
    // DeviceOs
  },
  methods: {
    async getData() {
      try {
        this.loading = true
        const res = await this.$api.common.getCountSummary()

        let systemVersionStatisticResVoList = []
        let deviceCloudPlatformStatisticResVoList = []
        let deviceFirmwareStatisticResVoList = []

        if (res.result) {
          if (res.result.deviceNumberStatisticResVo) {
            this.deviceNumberStatisticResVo = res.result.deviceNumberStatisticResVo
          }

          this.deviceWarningStatisticResVoList = res.result.deviceWarningStatisticResVoList || []

          this.deviceProvinceStatisticResVoList = res.result.deviceProvinceStatisticResVoList || []
          this.countryStatisticResVos = res.result.countryStatisticResVos || []

          this.deviceRegionStatisticResVoList =
            res.result.deviceRegionStatisticResVoList
              .sort((a, b) => b.deviceNumber - a.deviceNumber)
              .slice(0, 10) || []
          this.deviceAppStatisticResVoList =
            res.result.deviceAppStatisticResVoList
              .sort((a, b) => b.deviceNumber - a.deviceNumber)
              .slice(0, 10) || []

          // systemVersionStatisticResVoList = res.result.systemVersionStatisticResVoList.sort(
          //   (a, b) => b.deviceNumber - a.deviceNumber
          // )
          deviceFirmwareStatisticResVoList = res.result.deviceFirmwareStatisticResVoList.sort(
            (a, b) => b.deviceNumber - a.deviceNumber
          )

          deviceCloudPlatformStatisticResVoList = res.result.deviceCloudPlatformStatisticResVoList.sort(
            (a, b) => b.deviceNumber - a.deviceNumber
          )

          this.VersionCountList = [
            deviceFirmwareStatisticResVoList,
            deviceCloudPlatformStatisticResVoList
          ]
          // deviceFirmwareStatisticResVoList 固件
          // deviceCloudPlatformStatisticResVoList 云平台
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.home {
  flex: 1;
  width: 100%;
  background: #fcfcfc;
  overflow-x: auto;
  display: flex;

  .left,
  .right {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 25%;
    .item:first-child {
      min-height: 400px;
      height: 50%;
    }

    .item:last-child {
      min-height: 420px;
      margin-bottom: 50px;
      flex: 1;
    }
  }
  .middle {
    flex: 1;
    display: flex;
    margin: 0 40px 36px;
    flex-direction: column;
    width: 50%;
    .item {
      width: 100%;
    }
    .item:first-child {
      min-height: 470px;
      height: 60%;
    }

    .item:last-child {
      min-height: 290px;
      margin-top: 50px;
      flex: 1;
    }

    .deviceAlert {
      background: url('../../../../assets/img/cardbg2.png') left top no-repeat;
      background-size: 100% 100%;
    }
  }

  .left,
  .right {
    .item {
      width: 100%;
      background: url('../../../../assets/img/cardbg.png') left top no-repeat;
      background-size: 100% 100%;
      & + .item {
        margin-top: 20px;
      }
    }
  }
  .tabs-wrap {
    position: relative;
    .title {
      position: absolute;
    }
    .tabs {
      display: flex;
      border-bottom: 1px solid #cccccc;
      justify-content: flex-end;
      span {
        font-size: 14px;
        color: #000;
        padding: 0 8px 20px;
        cursor: pointer;
        &.active,
        &:hover {
          color: #c7000a;
          position: relative;
          &::after {
            content: '';
            position: absolute;
            bottom: -1px;
            left: 0;
            width: 100%;
            height: 2px;
            z-index: 22;
            background: #c7000a;
          }
        }
        & + span {
          margin-left: 45px;
        }
      }
    }
  }
}
</style>
