import axios from 'axios'
import { Message } from 'element-ui'
import utils from './utils'
import { PLATFORM, BASE_URL } from './constant'
import i18n from '@/i18n/index'
import store from '../store/index'
const service = axios.create({
  // baseURL: BASE_URL,
  // timeout: 20000
})

if (process.env.NODE_ENV === 'production') {
  service.defaults.baseURL = BASE_URL
}

const urls = [
  {
    name: '/login/api/login',
    matchs: ['/login']
  },
  {
    name: '/login/api/logout',
    matchs: ['/logout']
  }
]

service.interceptors.request.use(
  config => {
    config.headers.msgId = utils.formatTime(new Date(), 'YYYYMMDDhhmmss')
    if (PLATFORM === 'admin') {
      config.headers.system = 4
    } else {
      config.headers.system = 5
      //header增加orgId,spId
      const { currentMerchant } = store.state
      config.headers.orgId = currentMerchant.orgId || ''
      config.headers.spId = currentMerchant.id || ''
    }

    // zh_CN:中文 ru_RU:俄文 en_US:英文
    let locale = ''
    if (i18n.locale == 'en') {
      locale = 'en_US'
    } else if (i18n.locale == 'ruRU') {
      locale = 'ru_RU'
    } else {
      locale = 'zh_CN'
    }
    config.headers['Accept-Language'] = locale

    let apiType = PLATFORM === 'admin' ? '/admin/api' : '/sp/api'

    if (config.options?.isSelfBaseURL) {
      config.url = config.baseURL
    } else {
      let baseURL = apiType + config.url

      for (const i in urls) {
        const e = urls[i].matchs.find(_ => _ === config.url)

        if (e) {
          baseURL = urls[i].name
        }
      }

      config.url = baseURL
    }

    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    const res = response.data

    if (Array.prototype.toString.call(response.data) === '[object Blob]') {
      if (res.type.includes('application/json')) {
        const reader = new FileReader()
        reader.onload = function(event) {
          const content = reader.result
          const message = JSON.parse(content).summary // 错误信息
          Message({
            message: message || i18n.messages[i18n.locale].systemError,
            type: 'error',
            duration: 3 * 1000
          })
          // TODO 错误处理
        }
        reader.readAsText(res)

        return Promise.reject(message)
      } else {
        return response
      }
    }

    if (res.code !== 'OK' && res.status !== 0) {
      Message.closeAll()
      Message({
        message: res.summary || i18n.messages[i18n.locale].systemError,
        type: 'error',
        duration: 3 * 1000
      })
      // 过期
      if (res.code === 'INVALID_SESSION') {
        // Message({
        //   message: i18n.messages[i18n.locale].loginPage.expiredMsg,
        //   type: 'error',
        //   duration: 3 * 1000
        // })
        setTimeout(() => {
          location.href =
            location.protocol +
            '//' +
            location.host +
            location.pathname +
            location.search +
            '#/login'
        }, 100)
      }

      return Promise.reject(new Error(res.summary || i18n.messages[i18n.locale].systemError))
    } else {
      return res
    }
  },
  error => {
    // console.log('error')
    // console.log(error)
    Message.closeAll()
    Message({
      message: i18n.messages[i18n.locale].systemError,
      type: 'error',
      duration: 3 * 1000
    })

    return Promise.reject(error)
  }
)

export default service
