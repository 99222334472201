const mutations = {
  SET_STATE(state, data = {}) {
    const { key, value } = data
    state[key] = value

    //缓存右上角选择的客户
    if (key === 'currentMerchant' && state.userInfo?.id && value?.id) {
      sessionStorage.setItem(`userSpId_${state.userInfo.id}`, value.id)
    }
  },
  SET_USERINFO(state, data) {
    state.userInfo = data || {}
  },
  SET_TOKEN(state, data) {
    state.token = data
  },
  SET_CURRENT_PAGE(state, data) {
    state.currentPage = data
  },
  SET_CURRENT_TITLE(state, data) {
    state.currentTitle = data
  },
  SET_MENU(state, data) {
    state.headerLink = data
  },
  SET_HEADER_TABS(state, tabs) {
    state.headerTabs = tabs
  },
  SET_CURRENT_TAB(state, tab) {
    state.currentTab = tab
  }
}

export default mutations
