export default {
  data() {
    return {
      tableData: [],
      loading: false,
      params: {
        pageSize: 20,
        currentPage: 1
      },
      tmp_params: {},
      total: 0
    }
  },
  watch: {
    tmp_params: {
      handler() {
        this.getData()
      },
      deep: true
    },
    //右上角机构客户改变时，重新请求接口
    '$store.state.currentMerchant.id'(newValue, oldValue) {
      if (oldValue && newValue && newValue != oldValue) {
        if (this.getData) {
          this.firstIn = true
          this.params.currentPage = 1
          this.getData()
        }
      }
    }
  },
  created() {
    this.initParams()
    this.getData = this.$utils.debounce(this.getData, this.params.pageSize)
  },
  computed: {
    btnShow() {
      return this.$route.meta.auth
    }
  },
  methods: {
    initParams() {
      const query = this.$route.query

      for (const i in query) {
        query[i] = isNaN(Number(query[i])) ? query[i] : Number(query[i])
      }

      Object.assign(this.params, query)
      this.tmp_params = { ...this.params }
    },
    updateParams(key, val, format = false) {
      const params = { ...this.params }

      params.currentPage = 1
      params[key] = val
      Object.assign(this, { params })
    },
    replaceData() {
      this.params.currentPage = 1

      if (this.params.sn === '') {
        delete this.params.sn
      }

      const params = { ...this.params }

      this.tmp_params = params
    }
  }
}
