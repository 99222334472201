import systemManagement from '@/api/systemManagement'
import utils from '@/lib/utils'
import i18n from '@/i18n/index'

const actions = {
  setCurrentPage({ commit }, name) {
    commit('SET_CURRENT_PAGE', name)
  },
  setCurrentTitle({ commit }, name) {
    commit('SET_CURRENT_TITLE', name)
  },
  async renderMenu({ commit }) {
    try {
      const res = await systemManagement.resourceUser()

      if (res.result && res.result.length) {
        const resource = res.result
          .filter(_ => _.resourceName && _.resourceDesc)
          .sort((a, b) => a.orderNum - b.orderNum)

        const createTree = (resource, id = null) => {
          return resource
            .filter(item => !item.status)
            .filter(item => item.parentId === id)
            .map(item => ({
              icon: item.icon || 'icondaohang_shouye',
              name: item.resourceName,
              title: item.resourceDesc,
              list: createTree(resource, item.resourceId)
            }))
        }
        commit('SET_MENU', createTree(resource))
      }
    } catch (e) {
      console.log(e)
    }
  },
  delCurrentTab({ state, commit }, path) {
    if (!path) return

    const index = state.headerTabs.findIndex(item => item.path === path)
    let sname = ``

    if (index !== -1) {
      const tabs = [...state.headerTabs]
      tabs.splice(index, 1)

      if (path === state.currentTab && tabs.length) {
        let curTab = tabs[index - 1] ? tabs[index - 1] : tabs[0]
        sname = curTab.name
        commit('SET_CURRENT_TAB', curTab.path)
      }

      commit('SET_HEADER_TABS', tabs)
    }
    return sname
  },
  setHeaderTabs({ state, commit }, params) {
    const index = state.headerTabs.findIndex(item =>
      params.query.iframeName
        ? params.query.iframeName === item.query.iframeName
        : item.path === params.path
    )

    const tabs = [...state.headerTabs]

    if (index === -1) {
      tabs.push({ ...params, key: Date.now() })
    } else {
      tabs.splice(index, 1, { ...params, key: state.headerTabs[index].key })
    }

    commit('SET_HEADER_TABS', tabs)
    commit('SET_CURRENT_TAB', params.path)
  },
  //获取用户信息
  async renderUser({ commit }) {
    try {
      const res = await systemManagement.userCurrent()
      commit('SET_USERINFO', res.result || {})
    } catch (e) {
      console.log(e)
    }
  },
  //机构、客户处理
  async renderOrgan({ commit }, userInfo) {
    try {
      const { orgId, id, accountType } = userInfo
      let list = []
      let spTopList = [] //顶层客户列表
      let currentMerchant
      let sessionSpId = sessionStorage.getItem(`userSpId_${id}`)
      sessionSpId = sessionSpId ? Number(sessionSpId) : sessionSpId
      //客户数据加工函数
      const spHandleFun = sp => {
        sp.name = `${i18n.messages[i18n.locale].deviceList.store} - ${sp.name}`
        sp.parentId = sp.orgId
        sp.isMerchant = true
        sp.keyId = sp.id
        sp.id = sp.spId
        //获取默认服务商客户
        if (sessionSpId && sessionSpId === sp.spId) {
          currentMerchant = sp
        } else if (!currentMerchant) {
          currentMerchant = sp
        }
        return sp
      }
      //接口请求
      if (accountType === 3) {
        const res = await systemManagement.getShowUserDataPermission()
        list = (res.result?.dataPermissionList || []).map(a => {
          return { ...a, id: a.orgId }
        })
        spTopList = (res.result?.spList || []).map(sp => {
          return spHandleFun(sp)
        })
      } else {
        const res = await systemManagement.orgTree({ orgId })
        list = res.result || []
      }
      list = utils.transTreeToList(list)
      list = list
        .sort((a, b) => a.id - b.id)
        .map(a => {
          if (!a.childLength && !a.spList?.length) {
            //最后一层机构
            a.disabled = true
          }
          a.name = a.orgName
          a.spList = (a.spList || []).map(sp => {
            return spHandleFun(sp)
          })
          return a
        })

      list = [...utils.transTreeToList(list, [], { childKey: 'spList' }), ...spTopList].sort(
        (a, b) => a.id - b.id
      )
      const listTree = utils.transMenuToTreeData(list)
      commit('SET_STATE', { key: 'organTree', value: listTree || [] })
      commit('SET_STATE', { key: 'organList', value: list || [] })
      //设置默认机构服务商
      commit('SET_STATE', { key: 'currentMerchant', value: currentMerchant || {} })
    } catch (e) {
      console.log(e)
    }
  }
}

export default actions
