import axios from '../lib/request.js'
const api = {
  /* 客户管理 */

  //新增关联客户信息
  spRelationAdd: data => axios.post('/sp/relation/add', data),

  //删除关联客户信息
  spRelationDel: params => axios.delete('/sp/relation/delete', { params }),

  //根据条件查询客户信息列表
  spRelationList: data => axios.post('/sp/relation/listBySpId', data),

  //根据条件查询客户信息列表
  spList: data => axios.post('/sp/list', data),

  //查询可以新增用户的服务商
  spCanInsertAccountList: () => axios.get('/sp/canInsertUserList'),

  //获取当前客户名称
  spInfo: data => axios.post('/sp/getSpInfo', data),

  //查询
  spQuery: data => axios.post('/sp/query', data),

  //添加客户
  spAdd: data => axios.post('/sp/add', data),

  //删除客户
  spDelete: data => axios.post('/sp/delete', data),

  //根据客户ID查询单个客户信息
  spGet: data => axios.post('/sp/get', data),

  //更新客户信息
  spUpdate: data => axios.post('/sp/update', data),

  //最大限制
  getSpImposeConfig: params => axios.get('/sp/getSpImposeConfig', { params }),

  /* 客户账号管理 */

  //根据条件查询账户信息列表
  userList: data => axios.post('/user/list', data),

  //获取所有账户信息
  userListAll: data => axios.post('/user/listUser', data),

  //添加登录账户
  userAdd: data => axios.post('/user/add', data),

  //根据userID查询单个登录账户信息
  userGet: data => axios.post('/user/get', data),

  //删除
  userDelete: data => axios.post('/user/delete', data),

  //更新账户信息
  userUpdate: data => axios.post('/user/update', data),

  //修改账户密码
  updateUserPassword: data => axios.post('/user/updateUserPassword', data),

  //修改当前登录的管理员密码，需要输入旧密码
  userUpdatePassword: data => axios.post('/user/updatePassword', data),

  //获取当前用户信息
  userCurrent: data => axios.get('/user/getCurrentUser', data),

  /* 角色管理 */

  //角色列表
  roleList: data => axios.post('/role/list', data),

  //新增角色
  roleAdd: data => axios.post('/role/add', data),

  //更新角色
  roleUpdate: data => axios.post('/role/update', data),

  //删除角色
  roleDel: data => axios.post('/role/delete', data),

  //查询用户相关联的角色列表
  queryUserRoles: data => axios.post('/resource/queryByRoleIds', data),

  //所有资源
  resourceAll: params => axios.get('/resource/all', { params }),

  //当前所有资源
  resourceUser: params => axios.get('/resource/getUserResources'),

  // 分配权限
  permissionAssign: data => axios.post('/permission/assign', data),

  /* 资源管理 */

  //根据条件查询账户信息列表
  resourceList: data => axios.post('/resource/list', data),

  //添加资源
  resourceAdd: data => axios.post('/resource/add', data),

  //删除资源
  resourceDelete: data => axios.post('/resource/delete', data),

  //据ID查询资源信息
  resourceGet: data => axios.post('/resource/get', data),

  //修改资源
  resourceUpdate: data => axios.post('/resource/update', data),

  //操作日志
  logList: data => axios.post('/system/logger/list', data),

  //预警信息
  warnInfoList: data => axios.post('/device/warning/info/list', data),

  //预警配置
  warnConfig: data => axios.post('/device/warning/config/list', data),

  //预警配置修改
  warnConfigUpdate: data => axios.post('/device/warning/config/update', data),

  //资源列表
  spaceList: data => axios.post('/device/warning/space/list', data),

  //系统设置
  settingConfig: () => axios.post('/device/setting/config/list'),

  //系统设置
  settingConfigUpdate: data => axios.post('/device/setting/config/update', data),

  //电子秤提醒
  deviceRemindList: data => axios.post('/device/log/listRemindLog', data),

  //电子秤提醒
  deviceRemindExport: params =>
    axios.get('/device/log/exportRemindLog', { params, responseType: 'blob' }),

  //外设自检查询
  deviceSelfTestList: params => axios.get('/device/selfTest/page', { params }),

  //外设自检导出
  deviceSelfTestExport: params =>
    axios.get('/device/selfTest/export', { params, responseType: 'blob' }),

  //外设自检操作记录
  deviceSelfTestRecordList: params => axios.get('/device/selfTest/record', { params }),

  //外设自检操作记录
  deviceSelfTestDetailList: params => axios.get(`/device/selfTest/${params}`),

  //异常外设自检设置为正常
  deviceSelfTestSetToNormal: data => axios.put(`/device/selfTest/updateToNormal`, data),

  //机构添加修改
  orgSave: data => axios.post(`/org/insertOrUpdate`, data),

  //机构删除
  orgDelete: params => axios.get(`/org/delete`, { params }),

  //机构树
  orgTree: params => axios.get(`/org/tree`, { params }),

  //获取机构名称
  orgGetById: params => axios.get(`/org/getById`, { params }),

  //机构列表
  getUserDataPermission: params => axios.get(`/user/getUserDataPermission`, { params }),
  //机构列表
  getUserDataPermissionFilterSp: params =>
    axios.get(`/user/getUserDataPermissionFilterSp`, { params }),
  //机构列表(无type2机构)
  getShowUserDataPermission: params => axios.get(`/user/getShowUserDataPermission`, { params }),
  //获取机构下所有门店，包括下级机构的门店
  getOrgAllSp: params => axios.get(`/sp/getOrgAllSp`, { params }),
  //客户是否可编辑机构
  checkSpUser: params => axios.get(`/sp/checkSpUser`, { params })
}

export default api
