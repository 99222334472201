import Vue from 'vue'
import utils from './utils'
import * as api from '@/api/index'
import axios from 'axios'

import '@/lib/request'
import '@/lib/element'
import '@/assets/css/reset.scss'
import CommonComponents from '@/components/'
import CommonDirective from '@/directives/'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

dynamicLoadJs('/font_1738613_kmzqvpmp7hq.js')

function dynamicLoadJs(url, callback) {
  var head = document.getElementsByTagName('head')[0]
  var script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = url
  if (typeof callback == 'function') {
    script.onload = script.onreadystatechange = function() {
      if (!this.readyState || this.readyState === 'loaded' || this.readyState === 'complete') {
        callback()
        script.onload = script.onreadystatechange = null
      }
    }
  }
  head.appendChild(script)
}

Vue.use(CommonDirective)

Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999
  }
})

//只能整数
Vue.prototype.onlyNum = function(value) {
  value = value.replace(/[^\d]/g, '').replace(/\s/g, '')
  return value
}

CommonComponents.forEach(components => Vue.use(components))

export default function() {
  const plugins = {
    $api: api.default,
    $utils: utils,
    $axios: axios
  }

  Object.keys(plugins).forEach(key => {
    Vue.prototype[key] = plugins[key]
  })
}
