import { Message } from 'element-ui'
import mometn from 'moment'
import i18n from '@/i18n/index'
import * as XLSX from 'xlsx'

const utils = {
  formatNum(num) {
    if (num) {
      // toLocaleString功能最多保留三位小数 而且是整数部分每三位加一个逗号
      const target = Number(Number(num).toFixed(2)).toLocaleString()
      if (num % 1 === 0) {
        return `${target}.00`
      } else {
        const len = num.toString().split('.')[1].length
        return len === 1 ? `${target}0` : target
      }
    } else {
      return 0
    }
  },

  downloadFile(url) {
    url = url.replace(/\\/g, '/')
    const xhr = new XMLHttpRequest()
    xhr.open('GET', url, true)
    xhr.responseType = 'blob'
    // 文件下载进度
    xhr.onprogress = res => {
      this.loadingTip =
        i18n.messages[i18n.locale].fileDownloading +
        ((res.loaded / res.total) * 100).toFixed(2) +
        '%'
    }
    xhr.onload = () => {
      this.loadingTip = ''
      this.loading = false

      if (xhr.status === 200) {
        // 获取文件blob数据并保存
        var num = url.lastIndexOf('/') + 1
        //把参数和文件名分割开
        var fileName = url.substring(num).split('?')[0]
        var export_blob = new Blob([xhr.response])
        var save_link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a')
        save_link.href = URL.createObjectURL(export_blob)
        save_link.download = fileName
        save_link.click()
      }
    }
    this.loading = true
    xhr.send()
  },

  downloadUrlFile(url) {
    url = url.replace(/\\/g, '/')
    const xhr = new XMLHttpRequest()
    xhr.open('GET', url, true)
    xhr.responseType = 'blob'
    //xhr.setRequestHeader('Authorization', 'Basic a2VybWl0Omtlcm1pdA==');
    xhr.onload = () => {
      if (xhr.status === 200) {
        // 获取文件blob数据并保存
        var fileName = this.getFileName(url)
        this.saveAs(xhr.response, fileName)
      }
    }

    xhr.send()
  },
  saveAs(data, name) {
    var urlObject = window.URL || window.webkitURL || window
    var export_blob = new Blob([data])
    var save_link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a')
    save_link.href = urlObject.createObjectURL(export_blob)
    save_link.download = name
    save_link.click()
  },

  getFileName(url) {
    var num = url.lastIndexOf('/') + 1
    var fileName = url.substring(num)
    //把参数和文件名分割开
    fileName = decodeURI(fileName.split('?')[0])
    return fileName
  },

  setCookie(Cname, value, expiredays) {
    var exdate = new Date()
    exdate.setDate(exdate.getDate() + expiredays)
    document.cookie =
      Cname + '=' + escape(value) + (expiredays == null ? '' : ';expires=' + exdate.toGMTString())
  },

  getCookie(Cname) {
    if (document.cookie.length > 0) {
      let Cstart = document.cookie.indexOf(Cname + '=')
      if (Cstart !== -1) {
        Cstart = Cstart + Cname.length + 1
        let Cend = document.cookie.indexOf(';', Cstart)
        if (Cend === -1) Cend = document.cookie.length
        return unescape(document.cookie.substring(Cstart, Cend))
      }
    }
    return ''
  },

  delCookie(name) {
    var exdate = new Date()
    exdate.setTime(exdate.getTime() - 1)
    var cval = getCookie(name)
    if (cval != null) {
      document.cookie = name + '=' + cval + ';expires=' + exdate.toGMTString()
    }
  },

  isTel(tel) {
    // return /^([0-9]{3,4}-)?[0-9]{7,8}$/.test(tel)
    return true
  },
  isPhone(mobile) {
    // return /^1[3456789]\d{9}$/.test(mobile)
    return /^1\d{10}$/.test(mobile)
    // return true
  },
  isLandline(val) {
    const reg = /^([0-9]{3,4}-)?[0-9]{7,8}$/
    return reg.test(val)
  },
  isEMail(mail) {
    return /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/.test(
      mail
    )
  },

  isIDCard(idCard) {
    return /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/.test(
      idCard
    )
  },
  isUrl(url) {
    const reg = /^https?:\/\/(([a-zA-Z0-9_-])+(\.)?)*(:\d+)?(\/((\.)?(\?)?=?&?[a-zA-Z0-9_-](\?)?)*)*$/i
    return reg.test(url)
  },
  //非英文字符
  isNoEn(v) {
    if (typeof v === 'string') {
      return v.charCodeAt() > 255
    }
    return false
  },

  formatTime(val, type) {
    if (!val) {
      return ''
    } else {
      return mometn(val).format(type || 'YYYY-MM-DD HH:mm:ss')
    }
  },

  downFile(res) {
    const blob = new Blob([res.data], { type: res.headers['content-type'] })
    let fileName = 'download.xlsx'
    if (res.headers['content-disposition']) {
      fileName = window.decodeURIComponent(res.headers['content-disposition'].split('=')[1] || '')
    }

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, fileName)
    } else {
      const a = document.createElement('a')
      const href = URL.createObjectURL(blob)
      a.href = href
      a.download = fileName
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      URL.revokeObjectURL(href)
    }
  },

  bytesToSize(bytes, bool) {
    const k = 1024, // or 1024
      sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k))

    const objData = bytes / Math.pow(k, i)

    const round = (n, decimals = 0) => Number(`${Math.round(`${n}e${decimals}`)}e-${decimals}`)

    if (bool) {
      return {
        size: round(objData, 1) || 0,
        unit: sizes[i] || 'B'
      }
    } else {
      return (round(objData, 1) || 0) + ' ' + (sizes[i] || 'B')
    }
  },

  copyText(text) {
    const button = document.createElement('button')
    document.body.appendChild(button)

    const copyBtn = new ClipboardJS(button, {
      text: () => text
    })

    copyBtn.on('success', function(e) {
      Message.success(i18n.messages[i18n.locale].copySucceeded)
      e.clearSelection()
      document.body.removeChild(button)
    })
    copyBtn.on('error', function(e) {
      document.body.removeChild(button)
    })

    button.click()
  },
  debounce(fn, delay) {
    let timer = null
    return function(...args) {
      if (timer) clearTimeout(timer)
      timer = setTimeout(function() {
        fn.apply(this, args)
      }, delay)
    }
  },
  throttle(fn, delay) {
    let flag = true
    return function(...args) {
      if (!flag) return
      flag = false
      setTimeout(() => {
        fn.apply(this, args)
        flag = true
      }, delay)
    }
  },
  getRandIP() {
    const ip = [...Array(4).keys()].map(_ => Math.floor(Math.random() * 256)).join('.')
    return ip
  },
  getStartEndTime(type) {
    const date = 'YYYY-MM-DD'
    let startTime = this.formatTime(Date.now() - new Date().getHours() * 3600 * 1000, date)
    let endTime = this.formatTime(Date.now(), date)

    if (type === 'yesterday') {
      startTime = this.formatTime(Date.now() - (new Date().getHours() + 24) * 3600 * 1000, date)
      endTime = startTime
    } else if (type === 'recentSevenDays') {
      startTime = this.formatTime(Date.now() - 24 * 3600 * 1000 * 7, date)
    } else if (type === 'recentThirtyDays') {
      startTime = this.formatTime(Date.now() - 24 * 3600 * 1000 * 30, date)
    }

    return { startTime, endTime }
  },
  copyText(value, that) {
    const aux = document.createElement('input')
    aux.setAttribute('value', value)
    document.body.appendChild(aux)
    aux.select()
    document.execCommand('copy')
    document.body.removeChild(aux)
    that.$message.success(that.$t('copySucceeded'))
  },
  /**
   *使用 XLSX 将JSON数据导出为 excel
   * eg: .columns = [
   *    { header: 'Id', key: 'id', wch: 10 },
   *    { header: 'Name', key: 'name', wch: 32 },
   *    { header: 'D.O.B.', key: 'dob', width: 10, hidden: true }
   * ]
   * data: [{id: 1, name: 'John Doe', dob: new Date(1970,1,1)}]
   * @param columns 定义列数组
   * @param data  数据
   * @param name  文件名
   */
  exportExcel(columns = [], data = [], name = '') {
    const headers = columns.map(item => item.header)
    const otherConfigs = columns.map(({ key, header, ...item }) => item)

    const dataList = data.map(item => {
      let obj = {}
      columns.forEach(col => {
        obj[col.header] = item[col.key]
      })
      return obj
    })

    const workbook = XLSX.utils.book_new()
    workbook.SheetNames.push('Sheet1')
    const worksheet = XLSX.utils.json_to_sheet(dataList, {
      header: headers
    })
    worksheet['!cols'] = otherConfigs
    workbook.Sheets['Sheet1'] = worksheet

    // 生成Blob数据
    const excelData = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' })
    const blobData = new Blob([excelData], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    })

    // 创建Blob URL
    const blobUrl = URL.createObjectURL(blobData)
    // 创建一个隐藏的<a>标签并设置href属性为Blob URL
    const link = document.createElement('a')
    link.href = blobUrl
    link.target = '_blank'
    link.download = name || `download.xlsx`
    // 触发点击操作，开始下载文件
    link.click()
    // 释放Blob URL
    URL.revokeObjectURL(blobUrl)
    Message.success(i18n.messages[i18n.locale].succeeded)
  },
  deepClone(obj) {
    let result = typeof obj?.splice === 'function' ? [] : {}
    if (obj && typeof obj === 'object') {
      for (let key in obj) {
        if (obj[key] && typeof obj[key] === 'object') {
          result[key] = this.deepClone(obj[key]) //如果对象的属性值为object的时候，递归调用deepClone,即在吧某个值对象复制一份到新的对象的对应值中。
        } else {
          result[key] = obj[key] //如果对象的属性值不为object的时候，直接复制参数对象的每一个键值到新的对象对应的键值对中。
        }
      }
      return result
    }
    return obj
  },
  //使一级列表数据形成多级树结构数据
  transMenuToTreeData(listAll, options = {}) {
    if (!listAll) return []
    let list = JSON.parse(JSON.stringify(listAll))
    let key = options.key || 'id'
    let parentKey = options.parentKey || 'parentId'
    let childKey = options.childKey || 'children'
    let r = []
    let tmpMap = []
    for (let j = 0; j < list.length; j++) {
      tmpMap[list[j][key]] = list[j]
    }
    for (let i = 0; i < list.length; i++) {
      if (tmpMap[list[i][parentKey]] && list[i][key] != list[i][parentKey]) {
        if (!tmpMap[list[i][parentKey]][childKey]) tmpMap[list[i][parentKey]][childKey] = []
        tmpMap[list[i][parentKey]][childKey].push(list[i])
      } else {
        r.push(list[i])
      }
    }
    return r
  },
  //使多级树结构数据形成一级列表数据
  transTreeToList(treeList = [], arr = [], options = {}) {
    if (!treeList) return []
    let childKey = options.childKey || 'children'
    let needChildLeng = options.needChildLeng || '1'
    for (let item of treeList) {
      let newItem = { ...item }
      if (needChildLeng === '1') {
        newItem.childLength = item[childKey]?.length || 0
      }
      if (newItem[childKey]) {
        delete newItem[childKey]
      }
      arr.push(newItem)
      if (item[childKey]?.length) this.transTreeToList(item[childKey], arr)
    }
    return arr
  },
  //数组去重
  arrRemoveSame(arr = []) {
    const map = new Map()
    const newArr = []
    arr.forEach(item => {
      if (!map.has(item)) {
        // has()用于判断map是否包为item的属性值
        map.set(item, true) // 使用set()将item设置到map中，并设置其属性值为true
        newArr.push(item)
      }
    })
    return newArr
  }
}

export default utils
