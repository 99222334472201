//选择机构
<template>
  <el-cascader
    ref="SelectOrgan"
    v-model="val"
    :options="treeData"
    :props="cascaderProps"
    :show-all-levels="false"
    @change="organChange"
    clearable
    v-bind="$attrs"
  ></el-cascader>
</template>

<script>
export default {
  name: 'SelectOrgan',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {},
    //默认
    cascaderProps: {
      type: Object,
      default: () => {
        return { checkStrictly: true, emitPath: false, label: 'name', value: 'id' }
      }
    },
    //多选时的默认值
    defaultValues: {
      type: Array | null,
      default: null
    },
    //可以选择客户
    canSelectMerchant: {
      type: Boolean,
      default: false
    },
    //只能选择客户
    onlySelectMerchant: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      treeData: [], //树结构
      list: [], //一层数据结构
      val: null,
      preVal: null,
      selectListObj: null //选中的数组实体数据
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newV, oldV) {
        if (newV && newV != oldV) {
          this.val = newV
        }
      }
    },
    '$store.state.userInfo.orgId': {
      immediate: true,
      deep: true,
      handler(newV, oldV) {
        const { isAdmin } = this.$store.state
        if (!isAdmin && newV && newV != oldV) {
          this.getData(newV)
        } else if (isAdmin) {
          this.getData(undefined)
        }
      }
    }
  },
  created() {},
  methods: {
    async getData(orgId) {
      try {
        const { userInfo, isAdmin } = this.$store.state
        let treeData = []
        let spTopList = [] //顶层客户列表
        //客户数据加工函数
        const spHandleFun = sp => {
          sp.parentId = sp.orgId
          sp.isMerchant = true
          sp.keyId = sp.id
          sp.id = sp.spId
          return sp
        }
        //接口请求
        if (!isAdmin && userInfo.accountType === 3) {
          //客户平台且客户类型是3调此接口
          const res = await this.$api.systemManagement.getShowUserDataPermission()
          treeData = (res.result?.dataPermissionList || []).map(a => {
            return { ...a, id: a.orgId }
          })
          spTopList = (res.result?.spList || []).map(sp => {
            return spHandleFun(sp)
          })
        } else {
          const res = await this.$api.systemManagement.orgTree({ orgId })
          treeData = res.result || []
        }
        let list = this.$utils.transTreeToList(treeData).map(a => {
          a.name = a.orgName
          a.spLength = a.spList?.length || 0
          if (this.onlySelectMerchant && !a.childLength && !a.spLength) {
            //最后一层机构
            a.disabled = true
          }
          a.spList = (a.spList || []).map(sp => {
            return spHandleFun(sp)
          })
          return a
        })

        if (this.canSelectMerchant) {
          //多选且可选择客户时树结构数据重新整理
          list = [
            ...this.$utils.transTreeToList(list, [], { childKey: 'spList', needChildLeng: '0' }),
            ...spTopList
          ].sort((a, b) => a.id - b.id)
          treeData = this.$utils.transMenuToTreeData(list)
        } else {
          treeData = this.$utils.transMenuToTreeData(list)
        }

        this.treeData = treeData
        this.list = list

        this.multiInit()
      } catch (e) {
        console.log(e)
      } finally {
      }
    },
    multiInit() {
      if (this.cascaderProps.multiple && this.defaultValues?.length) {
        this.defaultValues.forEach(a => {
          if (a.type === 1) {
            //机构客户全部选中
            this.val = [...(this.val || []), a.orgId]
            this.organChange(this.val)
          } else if (a.type === 2) {
            //机构客户部分选中
            ;(a.spList || []).forEach(b => {
              this.val = [...(this.val || []), b.spId]
              this.organChange(this.val)
            })
          }
        })
      }
    },
    organChange(val) {
      if (this.cascaderProps.multiple) {
        val = val || []
        let preVal = this.preVal || []
        //多出的节点
        let newItems = val.filter(id => !preVal.includes(id))
        //减少的节点
        let reduceItems = preVal.filter(id => !val.includes(id))
        let res,
          type,
          changeChilds = []
        if (newItems.length === 1) {
          //子节点全部选中
          type = 'add'
          res = this.childrenHandle(val, newItems[0], type)
        } else if (reduceItems.length === 1) {
          //子节点全部勾掉
          type = 'reduce'
          res = this.childrenHandle(val, reduceItems[0], type)
        }
        if (res) {
          val = res.arr
          changeChilds = res.changeChilds
        }

        //子节点全选时选中置灰
        if (changeChilds.length) {
          const list = this.list.map(a => {
            if (changeChilds.includes(a.id)) {
              a.disabled = type === 'add'
            }
            return a
          })
          this.treeData = this.$utils.transMenuToTreeData(list)
        }
      } else {
        this.$refs.SelectOrgan.dropDownVisible = false
      }
      this.preVal = val

      //返回id
      this.$emit('change', val)
      this.val = val

      //返回实体
      let obj = this.cascaderProps.multiple
        ? this.list.filter(a => val.includes(a.id))
        : this.list.find(a => a.id === val)
      this.$emit('changeEvent', obj)
      this.selectListObj = obj
    },
    /**
     * 子节点处理
     *@param type add全选子节点，reduce去掉所有子节点
     *@param changeChilds 涉及到改变的子节点
     */
    childrenHandle(arr = [], id, type, changeChilds = []) {
      let item = this.list.find(a => a.id === id)
      if (!item?.childLength && !item?.spLength) return { arr, changeChilds }
      //获取所有子元素（还有子子元素，还没有做）
      let childs = this.list.filter(a => a.parentId === item.id).map(a => a.id)
      changeChilds = [...childs, ...changeChilds]
      if (type === 'add') {
        //全选子节点
        arr = this.$utils.arrRemoveSame([...arr, ...childs]) //去重
      } else if (type === 'reduce') {
        //去掉所有子节点
        arr = arr.filter(id => !childs.includes(id))
      }
      childs.forEach(childId => {
        const res = this.childrenHandle(arr, childId, type, changeChilds)
        arr = res.arr
        changeChilds = res.changeChilds
      })
      return { arr, changeChilds }
    },
    //如果下层全选只需要传父级,type=1,如果下层只选了部分客户，type=2，spIds逗号拼接
    getMultiResult() {
      let orgList = this.selectListObj
      let orgMap = new Map()
      orgList
        .filter(a => !a.disabled && !a.isMerchant)
        .forEach(a => {
          orgMap.set(a.id, {
            orgId: a.id,
            spIds: [], //客户，后续需要改成字符串,分隔
            type: 1 //1整个机构全部客户 2部分客户
          })
        })
      orgList
        .filter(a => !a.disabled && a.isMerchant)
        .forEach(a => {
          let existItem = orgMap.get(a.orgId)
          if (existItem) {
            let spIds = [...existItem.spIds, a.id]
            orgMap.set(a.orgId, { orgId: a.orgId, spIds, type: 2 })
          } else {
            orgMap.set(a.orgId, { orgId: a.orgId, spIds: [a.id], type: 2 })
          }
        })
      const results = [...orgMap].map(e => {
        e[1].spIds = e[1].spIds.join(',')
        return e[1]
      })
      return results
    }
  }
}
</script>

<style lang="scss" scoped></style>
