import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations'
import actions from './actions'

Vue.use(Vuex)

const state = {
  isAdmin: location.pathname.includes('admin.html'),
  userInfo: {},
  privateKey: 'rluz9wmszcf',
  origin: '',
  username: '',
  spId: '',
  currentPage: '',
  currentTitle: '',
  headerLink: [],
  currentTab: '',
  headerTabs: [], // 面包屑导航
  organTree: [], //机构树列表
  organList: [], //所有机构列表包含客户
  currentMerchant: {} //当前客户
}
export default new Vuex.Store({
  state,
  mutations,
  actions
})
