var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-header',{staticClass:"header",class:{
    specialHeader: _vm.specialHeader
  }},[_c('div',{staticClass:"rightPart"},[(!_vm.$store.state.isAdmin)?[_c('el-cascader',{ref:"cascaderOrg",staticClass:"headerCascader",attrs:{"placeholder":_vm.$t('placeholder'),"show-all-levels":false,"options":_vm.$store.state.organTree,"props":{
          emitPath: false,
          label: 'name',
          value: 'id'
        }},on:{"change":_vm.merchantChange},model:{value:(_vm.headerParams.spId),callback:function ($$v) {_vm.$set(_vm.headerParams, "spId", $$v)},expression:"headerParams.spId"}})]:_vm._e(),_c('icon-svg',{attrs:{"icon-class":"icontouxiang","class-name":"icons"}}),_c('el-menu',{staticClass:"el-menu-demo",attrs:{"default-active":"1","mode":"horizontal","background-color":"#f6f6f6","text-color":"#666","active-text-color":"#666"}},[_c('el-submenu',{attrs:{"index":"1"}},[_c('template',{slot:"title"},[_vm._v(_vm._s(_vm.title)+" "),(_vm.title)?_c('span',{staticStyle:{"color":"#074d29","font-size":"12px"}},[_vm._v("("+_vm._s(_vm.daysRemaining)+")")]):_vm._e()]),_c('el-menu-item',{on:{"click":function($event){_vm.visible = true}}},[_vm._v(_vm._s(_vm.$t('changePassword')))]),_c('el-menu-item',{on:{"click":_vm.logout}},[_vm._v(_vm._s(_vm.$t('signOut')))])],2)],1)],2),(_vm.visible)?_c('password-modal',{attrs:{"visible":_vm.visible},on:{"logout":_vm.logout,"update:visible":function($event){_vm.visible=$event}}}):_vm._e(),(_vm.visible2)?_c('space-modal',{attrs:{"visible":_vm.visible2},on:{"update:visible":function($event){_vm.visible2=$event}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }